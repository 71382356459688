import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layouts'

export const query = graphql`
{
  prismic{
    allProducts (where: { type: "print-framed"}) {
      edges{
        node{
          _meta{
            type
            id
            uid
          }
          product_name
          product_image
          sub_title
        }
      }
    }
  }
}
`

const RenderProductList = ({ products }) => {
  return products.map((item) =>
    <div key={item._meta.uid} className="col mb-4">
      <Link to={`/prints-with-frame/${item._meta.uid}`}>
        <div className="card text-white">
          <img src={item.product_image.url} className="card-img" alt={`Item product thumbnail: ${item.product_name}`} />
          <div className="card-img-overlay d-flex align-items-end justify-content-center" style={{ backgroundColor: "rgba(85, 85, 85, 0.4)"}}>
            <h5 className="card-title text-center">{RichText.asText(item.product_name)}</h5>
          </div>
        </div>
      </Link>
    </div>
  )
}

const RenderBody = ({ products }) => (
  <React.Fragment>
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4">
      <RenderProductList products={products} />
    </div>
    
  </React.Fragment>
)


export default ({ data }) => {
  console.log("DATA", data)
  if (!data) return null
  const docs = data.prismic.allProducts.edges.map((node) => node.node);

  console.log("PRODUCTS", docs)

  if(!docs) return null;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>{RichText.asText(doc.node.title)}</title> */}
      </Helmet>
      <RenderBody products={docs} />
    </Layout>
  );
}

